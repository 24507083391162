import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";

export const useDeleteOldScores = () => {
  const scoresCollectionRef = collection(db, "scores");
  const now = Date.now();
  const cutoff = now - 7 * 24 * 60 * 60 * 1000;
  const scoresQuery = query(
    scoresCollectionRef,
    where("created", "<", cutoff),
    orderBy("created", "asc")
  );

  const getScores = async () => {
    const data = await getDocs(scoresQuery);
    console.log(data.docs.length);
    if (data?.docs?.length) {
      data.docs.map((doc) => {
        deleteDoc(doc.ref);
        return false;
      });
    }
  };
  getScores();
  return {};
};

import styled from "styled-components";
import { useScores } from "./useScores";
import { ReactComponent as Winner } from "./ui/icons/trophy-alt-duotone.svg";

const HighScoreWrapper = styled.div`
  > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    background: var(--color-black);
    color: white;
    svg {
      max-width: 20px;
      margin-right: 1rem;
      color: var(--color-gold);
    }
  }
  table {
    width: 100%;
    margin: 0 auto;
    border-spacing: 0;
    font-size: 0.9rem;
    .name {
      text-align: left;
      padding-left: 1rem;
    }
    .pos {
      font-size: 0.8rem;
    }
    .pos,
    .score {
      background: #eee;
    }
    .score,
    .pos {
      font-weight: var(--font-weight-bold);
    }
    tr {
    }
    th,
    td {
      padding: 7px 5px;
      text-align: center;
      border-bottom: 1px solid #888;
    }
    th {
      background: #444;
      color: white;
      font-size: 0.8rem;
      font-weight: var(--font-weight-body);
      border: 1px solid var(--color-black);
    }
    td {
    }
  }
`;

export const HighScore = ({ level, numeracy }) => {
  const { scores } = useScores({ level, numeracy });
  return (
    <HighScoreWrapper>
      <h4 className="title">
        <Winner />
        Topplista senaste 7 dagarna
      </h4>
      <table>
        <thead>
          <tr>
            <th></th>
            <th className="name">Namn</th>
            <th>Tid</th>
            <th>Poäng</th>
          </tr>
        </thead>
        <tbody>
          {!scores ||
            (scores.length === 0 && (
              <tr>
                <td className="pos">1</td>
                <td className="name">Ingen tävlande här ännu</td>
                <td>?</td>
                <td className="score">?</td>
              </tr>
            ))}
          {scores.map((score, index) => (
            <tr key={index}>
              <td className="pos">{index + 1}</td>
              <td className="name">{score.name}</td>
              <td>{score.time}</td>
              <td className="score">{score.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </HighScoreWrapper>
  );
};

import { useContext } from "react";
import styled from "styled-components";
import { HighScore } from "./HighScore";
import { Button } from "./ui/Button";
import NumeracyContext from "./util/NumeracyContext";

const StartBlockWrapper = styled("div")``;

const StartButtonWrapper = styled("div")`
  margin-top: 0.5rem;
  padding: 1rem 0 3rem;
`;

const StartButton = styled(Button)`
  margin: 0 auto;
`;

function StartBlock({ scrollTo, setStart, table, start }) {
  const { numeracy } = useContext(NumeracyContext);
  if (!table || start) return null;

  return (
    <StartBlockWrapper>
      <StartButtonWrapper ref={scrollTo}>
        <StartButton
          onClick={() => {
            setStart(Date.now());
          }}
        >
          Starta!
        </StartButton>
      </StartButtonWrapper>
      <HighScore level={table} numeracy={numeracy} />
    </StartBlockWrapper>
  );
}

export default StartBlock;

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function shuffle(array) {
  var i = array.length,
    j = 0,
    temp;

  while (i--) {
    j = Math.floor(Math.random() * (i + 1));

    // swap randomly chosen element with current element
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export function getNumeracy(text) {
  switch (text) {
    case "addition":
      return { text: "addition", sign: "+" };
    case "subtraction":
      return { text: "subtraktion", sign: "-" };
    case "multiplication":
      return { text: "multiplikation", sign: "x" };
    case "division":
      return { text: "division", sign: "/" };
    default:
      return false;
  }
}

export const scrollTo = (ref) => {
  if (ref) {
    ref.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

import styled from "styled-components";
import { theme } from "../theme";
import { Button } from "./ui/Button";

const MenuWrapper = styled.div`
  text-align: center;
  h3 {
    margin-top: 0;
  }
`;
const Numbers = styled.div`
  display: flex;
  ${theme.media.sm} {
    flex-wrap: wrap;
  }
`;

const StyledButton = styled(Button)`
  border: 2px solid white;
  padding: 10px 5px;
  margin: 0;
  flex-grow: 1;
  background: var(--color-black);
  color: white;
  &.selected {
    background: var(--color-primary);
    color: var(--color-black);
  }
  ${theme.media.sm} {
    width: 20%;
  }
`;

export function Menu({ setTable, setStart, table, list, title }) {
  return (
    <MenuWrapper className="menu-wrapper">
      <h3>{title}</h3>
      <Numbers>
        {list.map((key, index) => {
          return (
            <StyledButton
              key={index}
              className={`${table === key ? "selected" : ""}`}
              onClick={() => {
                setStart(false);
                setTable(key);
              }}
            >
              {key}
            </StyledButton>
          );
        })}
      </Numbers>
    </MenuWrapper>
  );
}

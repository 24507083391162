import styled from "styled-components";
import { useContext, useState } from "react";
import { getNumeracy } from "./util/helpers";
import NumeracyContext from "./util/NumeracyContext";
import { Result } from "./Result";

const ReactorWrapper = styled.div`
  padding: 1.5rem 0 1rem;
  &.small-font {
    button.answer {
      font-size: 0.8rem;
    }
    .question {
      font-size: 0.9rem;
    }
  }
  &.multiplication {
    .last {
      font-weight: var(--font-weight-bold);
    }
  }
`;
const ReactorRow = styled.div`
  display: flex;
  margin-bottom: 1.7rem;
  align-items: center;
`;
const Question = styled.div`
  width: 90px;
`;
const Factor = styled.span`
  padding: 0 3px;
`;

const AnswersWrapper = styled.div`
  display: flex;
  width: calc(100% - 90px);
  justify-content: space-between;
`;

const AnswerButton = styled("button")`
  padding: 10px 0;
  width: 18%;
  font-size: 0.9rem;
  border: none;
  color: var(--color-font);
  background: #ddd;
  cursor: pointer;
  &.selected {
    color: white;
    &.correct {
      background: var(--color-green);
    }
    &.wrong {
      background: var(--color-red);
    }
  }
  &.correct {
    background: var(--color-light-green);
  }
`;

function Answers({ row, setAnswer, rowIndex }) {
  const { answers, correctNumber } = row;
  const [clickedNumber, setClicked] = useState(false);
  return (
    <AnswersWrapper>
      {answers.map((number, index) => {
        return (
          <AnswerButton
            key={index}
            className={`answer ${clickedNumber === number ? "selected" : ""} ${
              clickedNumber && (correctNumber === number ? "correct" : "wrong")
            }`}
            disabled={clickedNumber ? true : false}
            onClick={() => {
              setAnswer(rowIndex, correctNumber === number);
              setClicked(number);
            }}
          >
            {number}
          </AnswerButton>
        );
      })}
    </AnswersWrapper>
  );
}

export function Reactor({
  data,
  startTime,
  setStart,
  className,
  level,
  difficult = false,
}) {
  const [answers, setAnswers] = useState({});
  const { numeracy } = useContext(NumeracyContext);
  function setAnswer(index, correct) {
    if (typeof answers[index] === "undefined") {
      let update = { ...answers };
      update[index] = correct;
      setAnswers(update);
    }
  }
  const answerList = Object.entries(answers);
  return (
    <ReactorWrapper className={`${className} ${numeracy}`}>
      {data.map((row, index) => {
        return (
          <ReactorRow key={index}>
            <Question className="question">
              <Factor>{row.firstNumber}</Factor>
              <span className="sign">{getNumeracy(numeracy).sign}</span>
              <Factor className="last">{row.secondNumber}</Factor>
              <span className="equals">=</span>
            </Question>
            <Answers row={row} rowIndex={index} setAnswer={setAnswer} />
          </ReactorRow>
        );
      })}
      {answerList.length === data.length && (
        <Result
          answers={answerList}
          startTime={startTime}
          questions={data.length}
          setStart={setStart}
          difficult={difficult}
          numeracy={numeracy}
          level={level}
        />
      )}
    </ReactorWrapper>
  );
}

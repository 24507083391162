import styled from "styled-components";
import { ReactComponent as Winner } from "./ui/icons/trophy-alt-duotone.svg";
import { ReactComponent as Second } from "./ui/icons/trophy-duotone.svg";
import { ReactComponent as Third } from "./ui/icons/medal-duotone.svg";
import { ReactComponent as Fourth } from "./ui/icons/award-duotone.svg";
import { ReactComponent as Down } from "./ui/icons/thumbs-down-duotone.svg";
import { scrollTo } from "./util/helpers";
import { Button } from "./ui/Button";
import { useScores } from "./useScores";
import { useContext, useEffect, useState } from "react";
import NumeracyContext from "./util/NumeracyContext";

const ResultWrapper = styled.div`
  text-align: center;
  padding: 15px 0 20px;
  p {
    span {
      font-weight: var(--font-weight-bold);
    }
  }
  h3 {
    padding: 1rem 0;
    display: inline-block;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    margin: 2rem auto;
    svg {
      max-width: 30px;
      margin-right: 1rem;
      color: var(--color-primary);
      &.gold {
        color: var(--color-gold);
      }
      &.silver {
        color: var(--color-silver);
      }
      &.bronze {
        color: var(--color-bronze);
      }
      &.ok {
        color: var(--color-secondary);
      }
      &.bad {
        color: var(--color-red);
      }
    }
  }
`;

const ReStartButton = styled(Button)`
  margin: 0 auto;
`;

export function Result({
  answers,
  startTime,
  questions,
  setStart,
  difficult,
  level,
  numeracy,
}) {
  const { createScore } = useScores({ level, numeracy });
  const [created, setCreated] = useState(false);
  const { name } = useContext(NumeracyContext);

  const answerNumbers = answers.length;
  const correct = answers.filter((item) => item[1]).length;
  const seconds = Math.round((Date.now() - startTime) / 10) / 100;
  const percent = (correct / answerNumbers) * 100;
  const breakpoint = difficult ? 125 : 105;

  const points =
    Math.round(
      percent *
        10 *
        (breakpoint - (seconds < breakpoint ? seconds : breakpoint)) *
        0.01
    ) / 10;

  const text =
    correct === questions
      ? "Fantastiskt!"
      : correct > questions * 0.7
      ? "Bra jobbat!"
      : correct > questions * 0.4
      ? "Lite mer träning!"
      : "Aj då,";

  const props = {
    answers: correct,
    level,
    name,
    numeracy,
    score: points,
    time: seconds,
  };

  useEffect(() => {
    if (!created && name) {
      createScore(props);
      setCreated(true);
    }
  }, []);

  return (
    <ResultWrapper>
      <p>
        {text} du hade <span>{correct}</span> rätt av {answerNumbers} möjlliga.
      </p>
      <p>
        Du gjorde det på <span>{seconds}</span> sekunder.
      </p>
      <h3 ref={scrollTo}>
        {points > 90 ? (
          <Winner className="gold" />
        ) : points > 75 ? (
          <Second className="silver" />
        ) : points > 60 ? (
          <Third className="bronze" />
        ) : points > 45 ? (
          <Fourth className="ok" />
        ) : (
          <Down className="bad" />
        )}
        {points} poäng
      </h3>
      <ReStartButton
        className="secondary"
        onClick={() => {
          setStart(false);
        }}
      >
        Kör igen!
      </ReStartButton>
    </ResultWrapper>
  );
}

import { useState } from "react";
import styled from "styled-components";
import { randomIntFromInterval, scrollTo, shuffle } from "./util/helpers";
import { Menu } from "./Menu";
import { Reactor } from "./Reactor";
import StartBlock from "./StartBlock";

const MainWrapper = styled.div`
  padding: 1rem 1rem;
  .start-button {
    background: var(--color-primary);
  }
  &.scroll {
    min-height: 1000px;
  }
`;

function MultiplicationRender({ table, startTime, setStart }) {
  const isMix = table === "mix";

  const numberList = isMix
    ? shuffle(Array.from({ length: 12 }, (_, i) => i + 1)).slice(0, 10)
    : Array.from({ length: table > 10 ? table : 10 }, (_, i) => i + 1);

  const data = shuffle(numberList).map((secondNumber) => {
    const firstNumber = isMix ? randomIntFromInterval(1, 12) : table;
    const random =
      secondNumber < 2 ? 0 : secondNumber < 4 ? 1 : randomIntFromInterval(0, 4);
    const answersList = Array.from(
      { length: 5 },
      (_, i) => firstNumber * (secondNumber + (i - random))
    );
    const correctNumber = firstNumber * secondNumber;
    return {
      firstNumber,
      secondNumber,
      correctNumber,
      answers: shuffle(answersList),
    };
  });
  return (
    <Reactor
      data={data}
      startTime={startTime}
      setStart={setStart}
      level={table}
    />
  );
}

function Multiplication() {
  const [table, setTable] = useState(false);
  const [start, setStart] = useState(false);

  //MENU LIST
  var list = Array.from({ length: 13 }, (_, i) => {
    const number = i + 1;
    return number === 13 ? "mix" : number;
  });

  return (
    <MainWrapper className={table ? "scroll" : ""}>
      <Menu
        setTable={setTable}
        setStart={setStart}
        table={table}
        list={list}
        title="Välj multiplikationstabell"
      />
      <StartBlock {...{ scrollTo, setStart, table, start }} />
      {start && (
        <MultiplicationRender
          table={table}
          startTime={start}
          setStart={setStart}
        />
      )}
    </MainWrapper>
  );
}

export default Multiplication;

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAxUGqfmxCR6HgeXpROuCUD-puMC3KHNck",
  authDomain: "mattereaktorn.firebaseapp.com",
  projectId: "mattereaktorn",
  storageBucket: "mattereaktorn.appspot.com",
  messagingSenderId: "218625701237",
  appId: "1:218625701237:web:e04010944cd93c72340f3c",
  measurementId: "G-N5TGZP3WHW",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

import { useState } from "react";
import styled from "styled-components";
import { scrollTo, shuffle } from "./util/helpers";
import { Menu } from "./Menu";
import { Reactor } from "./Reactor";
import StartBlock from "./StartBlock";

const MainWrapper = styled.div`
  padding: 1rem 1rem;
  .start-button {
    background: var(--color-primary);
  }
  &.scroll {
    min-height: 1000px;
  }
`;

function DivisionRender({ table, startTime, setStart }) {
  const difficulty = {
    lätt: [4, 20, 1, 10],
    medel: [10, 50, 1, 20],
    svår: [50, 300, 5, 100],
    mega: [100, 2000, 5, 100],
  };
  const minNumber = parseInt(difficulty[table][0], 10);
  const maxNumber = parseInt(difficulty[table][1], 10);

  const dividendMinNumber = parseInt(difficulty[table][2], 10);
  const dividendMaxNumber = parseInt(difficulty[table][3], 10);

  let data = [];

  while (data.length < 10) {
    let dividend =
      Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
    let divisor =
      Math.floor(Math.random() * (dividendMaxNumber - dividendMinNumber + 1)) +
      dividendMinNumber;
    let quotient = Math.floor(dividend / divisor);

    // If quotient is not an integer, adjust dividend and divisor
    if (dividend % divisor !== 0) {
      var remainder = dividend % divisor;
      dividend += divisor - remainder;
      quotient = dividend / divisor;
    }

    // Check if the quotient is not 1
    if (quotient !== 1) {
      // Check if the problem is already generated
      var isDuplicate = data.some(function (problem) {
        return (
          problem.firstNumber === dividend && problem.secondNumber === divisor
        );
      });

      // If it's not a duplicate, generate wrong answers and add the problem
      if (!isDuplicate) {
        var wrongAnswers = [];
        while (wrongAnswers.length < 4) {
          var deviation = Math.floor(Math.random() * 5) + 1; // Random deviation between 1 and 5
          var sign = Math.random() < 0.5 ? -1 : 1; // Random sign for deviation
          var wrongAnswer = quotient + sign * deviation; // Adjusted wrong answer
          if (
            wrongAnswer !== quotient &&
            wrongAnswer > 0 &&
            Number.isInteger(wrongAnswer) &&
            wrongAnswers.indexOf(wrongAnswer) === -1
          ) {
            wrongAnswers.push(wrongAnswer);
          }
        }

        // Shuffle the wrong answers and add the correct answer
        var answers = wrongAnswers.concat(quotient);
        answers = shuffle(answers);
        data.push({
          firstNumber: dividend,
          secondNumber: divisor,
          correctNumber: quotient,
          answers: answers,
        });
      }
    }
  }

  return (
    <Reactor
      data={data}
      startTime={startTime}
      setStart={setStart}
      className={table === "mega" ? "small-font" : ""}
      difficult={table === "mega"}
      level={table}
    />
  );
}

function Division() {
  const [table, setTable] = useState(false);
  const [start, setStart] = useState(false);

  return (
    <MainWrapper className={table ? "scroll" : ""}>
      <Menu
        setTable={setTable}
        setStart={setStart}
        table={table}
        list={["lätt", "medel", "svår", "mega"]}
        title="Välj svårighetsgrad"
      />
      <StartBlock {...{ scrollTo, setStart, table, start }} />
      {start && (
        <DivisionRender table={table} startTime={start} setStart={setStart} />
      )}
    </MainWrapper>
  );
}

export default Division;

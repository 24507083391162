import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  orderBy,
  limit,
  query,
  where,
} from "firebase/firestore";

export const useScores = ({ numeracy, level }) => {
  const [scores, setScores] = useState([]);
  const scoresCollectionRef = collection(db, "scores");

  const scoresQuery = query(
    scoresCollectionRef,
    where("numeracy", "==", numeracy),
    where("level", "==", "" + level),
    orderBy("score", "desc"),
    orderBy("time", "asc"),
    limit(10)
  );

  const createScore = async ({
    answers,
    level,
    name,
    numeracy,
    score,
    time,
  }) => {
    console.log("create");
    await addDoc(scoresCollectionRef, {
      answers,
      created: Date.now(),
      level: "" + level,
      name,
      numeracy,
      score,
      time,
    });
  };

  useEffect(() => {
    const getScores = async () => {
      const data = await getDocs(scoresQuery);
      setScores(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getScores();
  }, [level, numeracy]);
  return { scores, createScore };
};

import { useContext, useState } from "react";
import styled from "styled-components";
import { randomIntFromInterval, scrollTo, shuffle } from "./util/helpers";
import { Menu } from "./Menu";
import { Reactor } from "./Reactor";
import StartBlock from "./StartBlock";
import NumeracyContext from "./util/NumeracyContext";

const MainWrapper = styled.div`
  padding: 1rem 1rem;
  .start-button {
    background: var(--color-primary);
  }
  &.scroll {
    min-height: 1000px;
  }
`;

function AdditionSubtractionRender({ table, startTime, setStart }) {
  const { numeracy } = useContext(NumeracyContext);
  const isSubtr = numeracy === "subtraction";
  const difficulty = {
    lätt: [1, 10],
    medel: [5, 20],
    svår: [10, 100],
    mega: [10, 1000],
  };
  const minNumber = parseInt(difficulty[table][0], 10);
  const maxNumber = parseInt(difficulty[table][1], 10);

  const listLength = maxNumber - minNumber > 10 ? maxNumber - minNumber : 10;

  const numberList = shuffle(
    Array.from({ length: listLength }, (_, i) =>
      isSubtr ? i + (minNumber + 1) : i + minNumber
    )
  ).slice(0, 10);

  const data = numberList.map((firstNumber) => {
    //SECOND NUMBER
    const secondNumber = randomIntFromInterval(
      minNumber,
      isSubtr ? firstNumber - 1 : maxNumber
    );

    //CORRECT NUMBER
    const correctNumber = isSubtr
      ? firstNumber - secondNumber
      : firstNumber + secondNumber;

    //BUILDING ANSWERS
    const random =
      isSubtr && correctNumber < 5 ? 0 : randomIntFromInterval(0, 4);
    const breakpoints = isSubtr ? [50, 100, 600] : [100, 600, 1000];
    const multiplier =
      correctNumber < breakpoints[0]
        ? 1
        : correctNumber < breakpoints[1]
        ? randomIntFromInterval(4, 5)
        : correctNumber < breakpoints[2]
        ? randomIntFromInterval(15, 16)
        : randomIntFromInterval(35, 36);
    const answersList = [
      ...Array.from({ length: 5 }, (_, i) => {
        const vari = i - random;
        return vari === 0
          ? correctNumber + vari
          : correctNumber + vari * multiplier;
      }),
    ];
    return {
      firstNumber,
      secondNumber,
      correctNumber,
      answers: shuffle(answersList),
    };
  });
  return (
    <Reactor
      data={data}
      startTime={startTime}
      setStart={setStart}
      className={table === "mega" ? "small-font" : ""}
      difficult={table === "mega"}
      level={table}
    />
  );
}

function AdditionSubtraction() {
  const [table, setTable] = useState(false);
  const [start, setStart] = useState(false);
  return (
    <MainWrapper className={`${table ? "scroll" : ""} `}>
      <Menu
        setTable={setTable}
        setStart={setStart}
        table={table}
        list={["lätt", "medel", "svår", "mega"]}
        title={"Välj svårighetsgrad"}
      />
      <StartBlock {...{ scrollTo, setStart, table, start }} />
      {start && (
        <AdditionSubtractionRender
          table={table}
          startTime={start}
          setStart={setStart}
        />
      )}
    </MainWrapper>
  );
}

export default AdditionSubtraction;

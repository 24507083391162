import { GlobalStyles } from "./theme";
import { ReactComponent as Logo } from "./components/ui/icons/flask-potion-duotone.svg";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "./components/ui/Button";
import Multiplication from "./components/Multiplication";
import AdditionSubtraction from "./components/AdditionSubtraction";
import { theme } from "./theme";
import { getNumeracy } from "./components/util/helpers";
import NumeracyContext from "./components/util/NumeracyContext";
import Division from "./components/Division";
import { useDeleteOldScores } from "./components/useDeleteOldScores";

const MainAppWrapper = styled.div``;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.header`
  .inner {
    max-width: 800px;
    margin: 0 auto;
  }
  background: var(--color-black);
  padding: 0.5rem 1rem;
  .logo {
    padding: 0 0 0.5rem;
    margin: 0;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    svg {
      max-width: 25px;
      margin-right: 10px;
      color: var(--color-secondary);
    }
    h1 {
      margin: 0;
      text-transform: uppercase;
      color: var(--color-primary);
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${theme.media.sm} {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const NameWrapper = styled.div`
  padding: 0.5rem 0;
  text-align: center;
  input {
    text-align: center;
    padding: 0 1rem;
    height: 40px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    ::placeholder {
      font-size: 14px;
    }
  }
`;

const StyledButton = styled(Button)`
  text-align: center;
  padding: 1rem 5px;
  width: 100%;
  background: var(--color-secondary);
  color: var(--color-black);
  &:not(:last-child) {
    margin-right: 1%;
  }
  ${theme.media.sm} {
    width: 49%;
    margin: 0 0 2% 0 !important;
  }
  &.selected {
    background: var(--color-primary);
  }
`;

function MainMenu({ numeracy, setNumeracy, numeracyList }) {
  return (
    <MenuWrapper>
      {numeracyList.map((item, index) => {
        return (
          <StyledButton
            key={index}
            className={`${item === numeracy ? "selected" : ""}`}
            onClick={() => {
              setNumeracy(item);
            }}
          >
            {getNumeracy(item).text}
          </StyledButton>
        );
      })}
    </MenuWrapper>
  );
}

function App() {
  const [numeracy, setNumeracy] = useState(false);
  const [name, setName] = useState(false);
  const numeracyList = [
    "addition",
    "subtraction",
    "multiplication",
    "division",
  ];
  const test = useDeleteOldScores();
  return (
    <NumeracyContext.Provider
      value={{
        numeracy,
        name,
      }}
    >
      <GlobalStyles />
      <MainAppWrapper className={numeracy ? "active" : ""}>
        <Header id="main-header">
          <div className="inner">
            <div className="logo">
              <Logo />
              <h1>Mattereaktorn</h1>
            </div>
            <MainMenu
              numeracy={numeracy}
              setNumeracy={setNumeracy}
              numeracyList={numeracyList}
            />
            <NameWrapper>
              <input
                placeholder="Skriv ditt namn för att hamna på topplistan"
                maxLength="30"
                onChange={(event) => {
                  const name = event?.target?.value;
                  if (name.length > 2 && name.length < 31)
                    setName(event.target.value);
                  else setName(false);
                }}
              />
            </NameWrapper>
          </div>
        </Header>
        <Wrapper>
          {numeracy === "multiplication" && (
            <Multiplication numeracy={numeracy} />
          )}
          {numeracy === "addition" && (
            <AdditionSubtraction numeracy={numeracy} />
          )}
          {numeracy === "subtraction" && (
            <AdditionSubtraction numeracy={numeracy} />
          )}
          {numeracy === "division" && <Division numeracy={numeracy} />}
        </Wrapper>
      </MainAppWrapper>
    </NumeracyContext.Provider>
  );
}

export default App;

import styled from "styled-components";

export const Button = styled.button`
  border: none;
  background: var(--color-primary);
  padding: 1rem 2rem;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  letter-spacing: 1px;
  &.secondary {
    background: var(--color-secondary);
  }
  &:disabled {
    opacity: 0.8;
    text-decoration: line-through;
  }
`;
